@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e3e3e3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.module-black {
  background-color: #000;
}

.module-fsilver {
  border: 5px solid #ddd;
}

.module-2 {
  /* height: 272.1px; */
  height: 10cm;
  /* width: 297px; */
  width: 10cm;
  /* top: 35%;
  left: 50%; */
  /* margin-left: -147.5px; */
}

.module-4 {
  height: 10cm;
  width: 15cm;
}

.module-6 {
  height: 88.900mm;
  width: 244.856mm;
}

.module-8 {
  height: 10cm;
  width: 24cm;
}

.module-12 {
  height: 16cm;
  width: 24cm;
}

.module-display {
  display: flex;
  transform: scale(0.75);
  align-items: center;
  justify-content: center;
}

.sub-module-2 {
  border: 1px solid #aaa;
  height: 5cm;
  /* height: 22.53vh; */
  width: 6cm;
  /* width: 10.937vw; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 2cm;
  margin-left: 2cm; */
  /* overflow: hidden; */
}

.sub-module-4 {
  border: 1px solid #aaa;
  height: 5cm;
  /* height: 22.53vh; */
  width: 10cm;
  /* width: 10.937vw; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 2cm;
  margin-left: 2cm; */
  /* overflow: hidden; */
}

.sub-module-6 {
  border: 1px solid #aaa;
  height: 46.000mm;
  /* height: 22.53vh; */
  width: 144.018mm;
  /* width: 10.937vw; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 2cm;
  margin-left: 2cm; */
  /* overflow: hidden; */
}

.sub-module-8 {
  border: 1px solid #aaa;
  height: 5cm;
  /* height: 22.53vh; */
  width: 18cm;
  /* width: 10.937vw; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 2cm;
  margin-left: 2cm; */
  /* overflow: hidden; */
}

.sub-module-12 {
  border: 1px solid #aaa;
  height: 5cm;
  /* height: 22.53vh; */
  width: 20cm;
  /* width: 10.937vw; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 2cm;
  margin-left: 2cm; */
  /* overflow: hidden; */
}

.switch {
  width: 140px;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.scene4 {
  /* width: 280px; */
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.nodeSize {
  width: 24mm;
}

.scene6 {
  height: 100%;
  /* width: 432px; */
  display: flex;
  justify-content: center;
  text-align: center;
}

.switch2Image {
  border: 1px solid #ffffff;
  width: 12mm;
  height: 12mm;
  /* background: url(./assets/General Switch.png); */
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.curtainImage {
  height: 145px;
  width: 140px;
  /* height: 22.376vh; */
  /* width: 10.937vw; */
  background-image: url(./assets/Curtain.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.sixampImage {
  right: 5mm;
  top: 5mm;
  height: 145px;
  /* height: 22.376vh; */
  width: 140px;
  /* width: 10.937vw; */
  background-image: url(./assets/6AMPSocket.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.socket16amp {
  right: 5mm;
  top: 5mm;
  height: 145px;
  width: 140px;
  background-image: url(./assets/16AMPSocket.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.switch2Imagesubnode1 {
  top: 15px;
  left: 25px;
}

.scene610-1subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-1subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene610-2subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-2subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene610-3subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-3subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene610-4subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-4subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene610-5subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-5subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene610-6subnode1 {
  top: 4.9985mm;
  left: 5.938mm;
}

.scene610-6subnode2 {
  top: 17.0015mm;
  left: 5.938mm;
}

.scene6-1subnode1 {
  top: 15px;
  right: -20px;
  /* left: -70px; */
}

.scene6-1subnode2 {
  top: 60px;
  right: -20px;
  /* left: -70px; */
}

.scene6-2subnode1 {
  top: 15px;
  right: -20px;
  /* right: 0px; */
}

.scene6-2subnode2 {
  top: 60px;
  right: -20px;
  /* right: 0px; */
}

.scene6-3subnode1 {
  top: 15px;
  right: -20px;
  /* right: -70px; */
}

.scene6-3subnode2 {
  top: 60px;
  right: -20px;
  /* right: -70px; */
}

.scene4f-1subnode1 {
  top: 15px;
  right: -20px;
  /* left: -45px; */
}

.scene4f-1subnode2 {
  top: 60px;
  right: -20px;
  /* left: -45px; */
}

.scene4f-2subnode1 {
  top: 15px;
  right: -10px;
}

.scene4f-2subnode2 {
  top: 60px;
  right: -10px;
}

.scene4f-subnodef {
  right: -35px;
}

.scene4f-subnoded {
  right: -50px;
}

.scene8f-subnodef {
  right: -100px;
}

.scene8f-subnoded {
  right: -110px;
}

.scene8f1-subnodef {
  right: 0px;
}

.scene8f1-subnoded {
  right: -10px;
}

.scene8f2-subnodef {
  right: -25px;
}

.scene8f2-subnoded {
  right: -40px;
}

.scene44-1subnode1 {
  top: 15px;
  right: -20px;
  /* left: -80px; */
}

.scene44-1subnode2 {
  top: 60px;
  right: -20px;
  /* left: -80px; */
}

.scene44-2subnode1 {
  top: 15px;
  right: -20px;
  /* right: -80px; */
}

.scene44-2subnode2 {
  top: 60px;
  right: -20px;
  /* right: -80px; */
}

.scene4-1subnode1 {
  top: 15px;
  right: -20px;
}

.scene4-1subnode2 {
  top: 60px;
  right: -20px;
}

.scene4-2subnode1 {
  top: 15px;
  right: -20px;
}

.scene4-2subnode2 {
  top: 60px;
  right: -20px;
}

.switch2Imagesubnode2 {
  top: 50px;
  left: 25px;
}

.switch2ImageFansubnode1 {
  top: 60px;
}

.switch2ImageFan {
  border: 1px solid #ffffff;
  width: 30px;
  height: 30px;
  /* background: url(./assets/Fan\ Default.png); */
  border-radius: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.switch2ImageDimmer {
  width: 40px;
  background: url(./assets/Dimmer\ Default.png);
  border-radius: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 150px;
  border-radius: 14px;
  position: relative;
}

.stepperdiv {
  border-radius: 0.5rem;
  margin: 1.5rem;
  background: white;
  padding: 1.5rem;
}

.headerdiv {
  text-align: right;
  background: white;
  padding: 1rem;
  width: 100%;
  display: flex;
}

.colorbar {
  width: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgb(135, 169, 248);
}

.stepsbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.isSelected {
  border: 3px solid #3b82f6;
}

.newOrder {
  background-color: #4CAF50;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 700;
}

.panel {
  font-size: 1rem;
  display: flex;
  height: 2rem;
}

.orderBox {
  margin-bottom: 1rem;
  padding: 1.25rem;
  width: 100%;
  border: 0 solid #e5e7eb;
  border-radius: .75rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .3);
}

.orderFont {
  font-weight: 500;
  font-size: .875rem;
}

.orderfooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.remote {
  margin-left: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.quantity {
  margin-left: 0.5rem;
  padding: 0.25rem;
  width: 60px;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.orderSummary {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.materialbox {
  background-color: #ffffff;
  min-width: 240px;
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.activestep {
  margin-left: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.mr-2 {
  margin-right: 1rem !important;
}